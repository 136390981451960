<template>
  <div class="container" id="create-investor">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="text-center mb-4">
        <img src="./../assets/logo_autotradebr.png" style="width:300px" />
      </div>
      <h2 class="text-center mb-4">Cadastre-se como investidor</h2>
      <div class="container mt-5">
        <div class="row">
          <div class="col-md-12 text-center">
            <p class="text-highlight">
              Se você já possui uma conta, faça <router-link to="/login">Login aqui</router-link> ou cadastre-se abaixo e comece a ganhar dinheiro com carros!
            </p>
          </div>
        </div>
      </div>
      <form @submit.prevent="cadastroInvestidor">
        <!-- Etapa 1: Dados Pessoais -->
        <div class="card mb-4">
          <div class="card-header bg-gradient-primary text-white">
            <h4 class="mb-0 text-start">Dados Pessoais</h4>
          </div>
          <div class="card-body">
            <div class="mb-3 input-group input-group-lg">
              <input
                type="text"
                class="form-control"
                id="nome"
                placeholder="Nome"
                v-model="nome"
                required
              />
            </div>
            <div class="mb-3 input-group input-group-lg">
              <input
                type="text"
                class="form-control"
                id="cpf"
                placeholder="CPF"
                v-model="cpf"
                v-mask="'###.###.###-##'"
                required
              />
            </div>
            <div class="mb-3 input-group input-group-lg">
              <input
                type="tel"
                class="form-control"
                id="telefone"
                placeholder="Telefone"
                v-model="telefone"
                v-mask="'(##) #####-####'"
                required
              />
            </div>
          </div>
        </div>
        <div class="card mb-4">
          <div class="card-header bg-gradient-primary text-white">
            <h4 class="mb-0 text-start">Dados de Endereço</h4>
          </div>
          <div class="card-body">
            <div class="mb-3 input-group input-group-lg">
                    <input
                      type="text"
                      class="form-control"
                      id="cep"
                      placeholder="CEP"
                      v-model="cep"
                      v-mask="'####-###'"
                      required
                    />
                  </div>
            <div class="mb-3 input-group input-group-lg">
                  <input
                    type="text"
                    class="form-control"
                    id="endereco"
                    placeholder="Informe endereço. Ex: Rua..."
                    v-model="endereco"
                    required
                  />
                </div>
            <div class="row">
              <div class="col">
                <div class="mb-3 input-group input-group-lg">
                  <input
                    type="text"
                    class="form-control"
                    id="numero"
                    placeholder="Número"
                    v-model="numero"
                    required
                  />
                </div>
              </div>
              <div class="col">
                <div class="mb-3 input-group input-group-lg">
                  <input
                    type="text"
                    class="form-control"
                    id="telefone"
                    placeholder="Complemento"
                    v-model="complemento"
                  />
                </div>
              </div>

            </div>
            <div class="mb-3 input-group input-group-lg">
                  <input
                    type="text"
                    class="form-control"
                    id="bairro"
                    placeholder="Bairro"
                    v-model="bairro"
                    required
                  />
                </div>
                <div class="mb-3 input-group input-group-lg">
                  <input
                    type="text"
                    class="form-control"
                    id="cidade"
                    placeholder="Cidade"
                    v-model="cidade"
                    required
                  />
                </div>
          </div>
        </div>

        <!-- Etapa 2: Dados de Usuário -->
        <div class="card mb-4">
          <div class="card-header bg-gradient-primary text-white">
            <h4 class="mb-0 text-start">Dados de Usuário</h4>
          </div>
          <div class="card-body">
            <div class="mb-3 input-group input-group-lg">
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <div class="mb-3 input-group input-group-lg">
              <input
                type="email"
                class="form-control"
                id="confirmacao-email"
                placeholder="Confirme seu email"
                v-model="confirmacaoEmail"
                required
              />
            </div>
            <div class="mb-3 input-group input-group-lg">
              <input
                type="password"
                class="form-control"
                id="password"
                placeholder="Senha."
                v-model="password"
                required
              />
            </div>
            <div class="mb-3 input-group input-group-lg">
              <input
                type="password"
                class="form-control"
                id="confirmacao-password"
                placeholder="Confirme a sua senha"
                v-model="confirmacaoPassword"
                required
              />
            </div>
          </div>
        </div>

        <!-- Etapa 3: Dados Bancários -->
        <div class="card mb-4">
          <div class="card-header bg-gradient-primary text-white">
            <h4 class="mb-0 text-start">Dados Bancários</h4>
          </div>
          <div class="card-body">
            <div class="mb-3 input-group input-group-lg">
              <select class="form-select" id="bank-select" v-model="banco" required>
                <option value="" selected>Informe o seu banco</option>
                <option v-for="bank in listaBancos" :key="bank.code" :value="bank.name">{{ bank.name }}</option>
              </select>
            </div>
            <div class="mb-3 input-group input-group-lg">
              <select class="form-select" id="bank-select" v-model="tipoChavePix" required>
                <option value="" selected>Tipo de chave Pix</option>
                <option v-for="tipoChave in tiposChavePix" :key="tipoChave" :value="tipoChave">{{ tipoChave }}</option>
              </select>
            </div>
            <div class="mb-3 input-group input-group-lg">
              <input
                type="text"
                class="form-control"
                id="pix"
                placeholder="Chave Pix."
                v-model="pix"
                required
              />
            </div>
            <div class="row">
              <div class="col">
                <div class="mb-3 input-group input-group-lg">
                  <input
                    type="text"
                    class="form-control"
                    id="agencia"
                    placeholder="Agencia."
                    v-model="Agencia"
                    required
                  />
                </div>
              </div>
              <div class="col">
                <div class="mb-3 input-group input-group-lg">
                  <input
                    type="text"
                    class="form-control"
                    id="conta"
                    placeholder="Conta."
                    v-model="conta"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

            <div class="card mb-4">
              <div class="card-header bg-gradient-primary text-white">
                <h4 class="mb-0 text-start">Investimento</h4>
              </div>
              <div class="card-body">
                  <div class="mb-3 input-group input-group-lg">
                    <select
                      class="form-select"
                      id="valor-do-aporte"
                      v-model="valueOfApport"
                    >
                      <option value="" selected>Escolha as opções de aporte</option>
                      <option v-for="apport in apports" :key="apport.value" :value="apport.value">{{ apport.text }}</option>
                    </select>
                  </div>
              </div>
            </div>

        <div class="d-grid">
          <button type="submit" class="btn bg-gradient-primary ">
            Próximo
          </button>
        </div>
        <p class="text-center mb-4">
          Ao se cadastrar, você aceita os
          <a href="#" target="_blank">Termos de Uso</a>
        </p>
      </form>
    </div>
  </div>
</div>
</template>
<style scoped>
#create-investor {
  margin-top: 100px;
}
.inova-auto-trade {
  color: #337ab7; /* cor azul padrão do Bootstrap */
}
.text-highlight {
  font-size: 22px;
}
</style>
<script>
import { mask } from "vue-the-mask";
import axios from "axios";
import Swal from "sweetalert2";
import { isCPF } from "validation-br";
import { token, base_url } from "./../config";

export default {
  name: "CreateInvestor",
  inject: ['$root'],
  mounted() {
    if (this.$root.isLogged) {
       this.$router.push({ name: 'home' });
    }
  },
  directives: {
    mask,
  },
  data() {
    return {
      nome: "",
      cpf: "",
      telefone: "",
      email: "",
      confirmacaoEmail: "",
      password: "",
      confirmacaoPassword: "",
      isPasswordValid: false,
      isEmailValid: false,
      isCpfValid: false,
      valueOfApport: "",
      apports:[
        { text:'R$ 30.000,00', value:30000 },
        { text:'R$ 100.000,00', value:100000 },
        { text:'R$ 300.000,00', value:300000 },
        { text:'R$ 500.000,00', value:500000 }
        ],
        endereco:'',
        numero:'',
        bairro:'',
        complemento:'',
        cep:'',
        pix:'',
        banco:'',
        listaBancos:[
            { code: '001', name: 'Banco do Brasil S.A.' },
            { code: '237', name: 'Bradesco S.A.' },
            { code: '341', name: 'Itaú Unibanco S.A.' },
            { code: '104', name: 'Caixa Econômica Federal (CEF)' },
            { code: '033', name: 'Banco Santander Brasil S.A.' },
            { code: '041', name: 'Banrisul – Banco do Estado do Rio Grande do Sul S.A.' },
            { code: '260', name: 'NU PAGAMENTOS S.A. (NUBANK)' },
            { code: '290', name: 'Pagseguro Internet S.A. (PagBank)' },
            { code: '380', name: 'PicPay Serviços S.A.' },
            { code: '323', name: 'Mercado Pago - conta do Mercado Livre' },
            { code: '077', name: 'Banco Inter S.A.' },
            { code: '237', name: 'NEXT BANK (mesmo código do Bradesco)' },
            { code: '637', name: 'Banco Sofisa S.A. (Sofisa Direto)' },
            { code: '212', name: 'Banco Original S.A.' },
            { code: '756', name: 'Banco Cooperativo do Brasil (BANCOOB)' },
            { code: '655', name: 'Banco Votorantim S.A.' },
            { code: '655', name: 'Neon Pagamentos S.A. (mesmo código do Banco Votorantim)' },
            { code: '335', name: 'Banco Digio S.A.' }

        ],
        tiposChavePix:['CPF', 'Celular', 'E-mail'],
        tipoChavePix:'',
        agencia:'',
        conta:''
    };
  },
  watch: {
    password() {
      this.validatePassword();
    },
    confirmacaoPassword() {
      this.validatePassword();
    },
    email() {
      this.validateEmail();
    },
    confirmacaoEmail() {
      this.validateEmail();
    },
    cpf() {
      this.validateCpf();
    },
  },
  methods: {
    validateCpf() {
      this.isCpfValid = isCPF(this.cpf);
    },
    validatePassword() {
      this.isPasswordValid = this.password === this.confirmacaoPassword;
    },
    validateEmail() {
      this.isEmailValid = this.email === this.confirmacaoEmail;
    },
    async createUser() {
      try {
        return await axios.post(
          base_url + "/api/auth/local/register",
          {
            username: this.email,
            email: this.email,
            password: this.password,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        Swal.fire({
          title: "Oops...",
          text: "Aconteceu algum problema! Tente novamente em instantes.",
          icon: "error",
          confirmButtonText: "Tentar novamente",
        });
        return false;
      }
    },
    async createInvestor(idUser) {
      try {
        return await axios.post(
          base_url + "/api/investors",
          {
            data: {
              IdUser: idUser.toString(),
              Name: this.nome,
              Telephone: this.telefone,
              Email: this.email,
              PersonalCode: this.cpf,
              Bank: this.banco,
              TypePixKey: this.tipoChavePix,
              PixKey: this.pix,
              BankAgency: this.agencia,
              BankAccount: this.conta,
              Address: this.endereco,
              Number: this.numero,
              Neighborhood: this.bairro,
              Complement: this.complemento,
              PostalCode: this.cep
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        Swal.fire({
          title: "Oops...",
          text: "Aconteceu algum problema! Tente novamente em instantes.",
          icon: "error",
          confirmButtonText: "Tentar novamente",
        });
        return false;
      }
    },
    async createInvestment(idInvestor) {
      try {
        return await axios.post(
          base_url + "/api/investiments",
          {
            data: {
              DateOfFormalization: new Date().toISOString(),
              ValueOfApport: parseFloat(this.valueOfApport), // Example value
              IdInvestor: idInvestor.toString(),
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        Swal.fire({
          title: "Oops...",
          text: "Aconteceu algum problema! Tente novamente em instantes.",
          icon: "error",
          confirmButtonText: "Tentar novamente",
        });
        return false;
      }
    },
    async cadastroInvestidor() {
      if (!this.isCpfValid) {
        Swal.fire({
          title: "Oops...",
          text: "CPF inválido!",
          icon: "error",
          confirmButtonText: "Tentar novamente",
        });
        return false;
      }

      if (!this.isPasswordValid) {
        Swal.fire({
          title: "Oops...",
          text: "Os campos senha e confirmação de senha não estão coincidindo!",
          icon: "error",
          confirmButtonText: "Tentar novamente",
        });
        return false;
      }

      if (!this.isEmailValid) {
        Swal.fire({
          title: "Oops...",
          text: "Os campos e-mail e confirmação de e-mail não estão coincidindo!",
          icon: "error",
          confirmButtonText: "Tentar novamente",
        });
        return false;
      }
   
      const responseUser = await this.createUser();
      const responseinvestor = await this.createInvestor(responseUser.data.user.id);
      await this.createInvestment(responseinvestor.data.data.id);
       Swal.fire({
          title: "Cadastro realizado com sucesso!",
          text: "Você receberá um e-mail com o contrato para assinar e as informações de pagamento.",
          icon: "success",
          confirmButtonText: "OK!",
        });
         this.$router.push({ name: 'login' });
      


      // Aqui você pode adicionar a lógica para cadastrar o investidor
      console.log("Nome:", this.nome);
      console.log("CPF:", this.cpf);
      console.log("Telefone:", this.telefone);
      console.log("Email:", this.email);
      console.log("Confirmação de Email:", this.confirmacaoEmail);
    },
  },
};
</script>
