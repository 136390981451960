import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import router from './router'
import money from  'vuejs-money'



const app = createApp(App)

// Definir as propriedades iniciais da $root
app.config.globalProperties.$root = {
    isLogged: false,
    tokenUser: ''
}
app.use(money)
app.use(router)
app.mount('#app')
