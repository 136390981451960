import { createRouter, createWebHistory  } from 'vue-router'

const resetAsideMenu = () => {
  // Lógica do middleware
  let asideComponent = document.getElementById('sidenav-main');
  asideComponent.classList.add('asideBar')
  console.log('Middleware executado antes da rota');

};

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/LoginAuth.vue')

    },
    {
      path: '/',
      name: 'HelloWorld',
      component:  () => import('./views/CreateInvestor.vue')

    },
    {
      path: '/home',
      name: 'home',
      component:  () => import('./views/Home.vue'),
      beforeEnter: resetAsideMenu

    },
    {
      path: '/meus-veiculos',
      name: 'MeusVeiculos',
      component:  () => import('./views/MyVehicles.vue'),
      beforeEnter: resetAsideMenu

    },
    {
      path: '/meus-investimentos',
      name: 'MeusInvestimentos',
      component:  () => import('./views/MyInvestments.vue'),
      beforeEnter: resetAsideMenu

    },
    {
      path:'/resgatar-invetimentos',
      name: 'ResgatarInvestimentos',
      component: () => import('./views/RescueInvestments.vue'),
      beforeEnter: resetAsideMenu
    },
    {
      path:'/solicitar-resgate',
      name: 'SolicitarResgate',
      component: () => import('./views/CreateRescueInvestment.vue'),
      beforeEnter: resetAsideMenu
    },
    {
      path:'/novo-investimento',
      name: 'NovoInvestimento',
      component: () => import('./views/CreateNewInvestment.vue'),
      beforeEnter: resetAsideMenu
    }
  ]
})



export default router