<!-- src/views/Login.vue -->
<template>
  <div>
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue'

export default {
  name:'loginAuth',
  components: { LoginForm }
}
</script>