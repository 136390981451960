<template>
<main class="main-content  mt-0">
    <div class="page-header align-items-start min-vh-100" :style="{ backgroundImage: `url(${require('./../assets/img/login_background.jpg')})` }">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container my-auto">
        <div class="row">
          <div class="col-lg-4 col-md-8 col-12 mx-auto">
            <div class="card z-index-0 fadeIn3 fadeInBottom">
              <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div class="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
                  <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                    <img
                      src="./../assets/logo.png"
                      class="navbar-brand-img"
                      alt="main_logo"
                      style="width:25vh;"
                    />
                  </h4>
                </div>
              </div>
              <div class="card-body">
                <form role="form" class="text-start" @submit.prevent="login">
                  <div class="input-group input-group-outline my-3">
                    <input type="email" class="form-control" id="email" placeholder="Email" v-model="email">
                  </div>
                  <div class="input-group input-group-outline mb-3">
                    <input type="password" class="form-control" id="password" placeholder="Senha" v-model="password">
                  </div>
                  <div class="text-center">
                    <button type="submit" class="btn bg-gradient-primary w-100 my-4 mb-2">Entrar</button>
                  </div>
                  <p class="mt-4 text-sm text-center">
                    Você ainda não se tornou um investidor?
                    <router-link to="/" class="text-primary text-gradient font-weight-bold">Cadastre-se e faça o seu investimento</router-link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>


<script>
import axios from "axios";
import Swal from "sweetalert2";
import { /*token,*/ base_url } from "./../config";


export default {
  name:"LoginForm",
  inject: ['$root'],
  data() {
    return {
      email: '',
      password: ''
    }
  },
  mounted() {
    if (this.$root.isLogged) {
       this.$router.push({ name: 'home' });
    }
  },
  methods: {
    async login() {

        try {
            const response = await axios.post(base_url+'/api/auth/local', {
              identifier: this.email,
              password: this.password
            });
            const userToken = response.data.jwt;
            localStorage.setItem('token',userToken);
            this.$root.isLogged = true;
            this.$root.tokenUser = userToken;

            const responseInvestor = await axios.get(base_url+'/api/investors?filters[IdUser][$eq]='+response.data.user.id, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              }
            });
            
            if(!responseInvestor.data.data){
              localStorage.removeItem('token');
              this.$root.isLogged = false;
              this.$root.tokenUser = '';
                 Swal.fire({
                title: "Oops...",
                text: "Usuário e senha não encontrado.",
                icon: "error",
                confirmButtonText: "Tentar novamente",
              });
              return false;
            }
            localStorage.setItem('investor', JSON.stringify(responseInvestor.data.data[0]));
            this.$root.investor = responseInvestor.data[0];
          this.$router.push({ name: 'home' });
        }catch(error){
            Swal.fire({
                title: "Oops...",
                text: "Usuário e senha não encontrado.",
                icon: "error",
                confirmButtonText: "Tentar novamente",
              });
          return false;

        }
    }
  }
}
</script>

<style scoped>
.card {
  max-width: 400px;
  width: 100%;
}
</style>