<template>
  <aside
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark ps bg-white asideBar"
    id="sidenav-main" v-if="isLogged"
    ref="asideMain"
  >
    <div class="sidenav-header">
      <div class="btnCloseAside" style="padding-top:10px; padding-right:10px; text-align: right;" @click="closeAsideMenu()">
        <i
        class="fas fa-xmark fa-1x" style="color:white;"
        id="iconSidenav"
      ></i>
      </div>
      <div>
            <router-link
            class="navbar-brand m-0"
            to="/home"
          >
            <img
              src="./assets/logo.png"
              class="navbar-brand-img h-100"
              alt="main_logo"
            />
          </router-link>

      </div>
    </div>
    <hr class="horizontal light mt-0 mb-2" />
    <div class="collapse navbar-collapse w-auto ps" id="sidenav-collapse-main" style="padding-top:20px;">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link class="nav-link  text-white" to="/home">
            <div
              class="text-white text-center me-2 d-flex align-items-center justify-content-center"
            >
              <i class="fas fa-solid fa-chart-line fa-1x opacity-10 "></i>
            </div>
            <span class="nav-link-text ms-1">Dashboard</span>
          </router-link>
        </li>
        <li class="nav-item">
           <router-link class="nav-link  text-white" to="/meus-veiculos">
            <div
              class="text-white text-center me-2 d-flex align-items-center justify-content-center"
            >
              <i class="fas fa-car fa-1x opacity-10 "></i>
            </div>
            <span class="nav-link-text ms-1">Meus Veículos</span>
           </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link  text-white" to="/meus-investimentos">
            <div
              class="text-white text-center me-2 d-flex align-items-center justify-content-center"
            >
              <i class="fa fa-usd  fa-1x opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Meus Investimentos</span>
           </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link  text-white" to="/resgatar-invetimentos">
            <div
              class="text-white text-center me-2 d-flex align-items-center justify-content-center"
            >
              <i class="fas   fa-solid fa-wallet fa-1x opacity-10"></i>
            </div>
            <span class="nav-link-text ms-1">Meus Resgates</span>
           </router-link>
        </li>
        <li class="nav-item btnsInvestimentOps">
          <router-link
          class="btn btn-danger w-90"
          to="/novo-investimento" 
          type="button"
          >Realizar Novo Investimento</router-link>
        </li>
        <li class="nav-item btnsInvestimentOps">
          <router-link
          class="btn btn-success w-90"
          to="/solicitar-resgate"
          type="button"
          >Realizar Resgate</router-link>
        </li>
      </ul>
      <div class="ps__rail-x" style="left: 0px; bottom: 0px">
        <div
          class="ps__thumb-x"
          tabindex="0"
          style="left: 0px; width: 0px"
        ></div>
      </div>
      <div class="ps__rail-y" style="top: 0px; right: 0px">
        <div
          class="ps__thumb-y"
          tabindex="0"
          style="top: 0px; height: 0px"
        ></div>
      </div>
    </div>
    <div class="sidenav-footer position-absolute w-100 bottom-0">
      <div class="mx-3">
        <a
          class="btn btn-outline-primary mt-4 w-100"
          href="https://drive.google.com/file/d/1EVsabT-bTIzCc2vL3avR6zZ3iMDUHv6P/view?usp=sharing" target="_blank"
          type="button"
          >Contrato</a
        >
        <a
          class="btn bg-gradient-info w-100"
          href="https://wa.me/5511940788249?text=Ol%C3%A1%2C%20gostaria%20de%20um%20atendimento."
          target="_blank"
          type="button"
          >Fale conosco</a
        >
        <a
                href="#" @click="logout()"
                 class="btn bg-gradient-primary w-100 btnSair"
              >
                <i class="fa fa-user me-sm-1" style="color:#FFF;" ></i> Sair
              </a>
      </div>
    </div>
    <div class="ps__rail-x" style="left: 0px; bottom: 0px">
      <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
    </div>
    <div class="ps__rail-y" style="top: 0px; right: 0px">
      <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px"></div>
    </div>
  </aside>
  <main class="main-content border-radius-lg">
    <nav
      class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl header-responsivo"
      id="navbarBlur"
      data-scroll="true"
      v-if="isLogged"
    >
      <div class="container-fluid py-1 px-3">
        <div
          class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
          id="navbar"
        >
        <div class="btn-burger" id="btnBurger" @click="openAsideMenu()">
          <i class="fa-solid fa-bars"></i>
        </div>

        <img
          src="./assets/logo.png"
          class="navbar-brand-img h-100"
          alt="main_logo"
        />
          <div class="ms-md-auto pe-md-3 d-flex align-items-center">
          </div>
          <ul class="navbar-nav justify-content-end">
            <li class="nav-item d-flex align-items-center">
              <router-link
                class="btn btn-outline-danger btn-sm mb-0 me-3"
                to="/novo-investimento"
                >Realizar Novo Investimento</router-link>
            </li>
            <li class="nav-item d-flex align-items-center">
              <router-link
                class="btn btn-outline-success btn-sm mb-0 me-3"
                to="/solicitar-resgate"
                >Resgatar Investimento</router-link>
            </li>
            <li class="nav-item d-flex align-items-center">
              <a
                href="#" @click="logout()"
                class="nav-link text-body font-weight-bold px-0"
              >
                <i class="fa fa-user me-sm-1"></i> Sair
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- End Navbar -->
    <div id="app">
      <router-view></router-view>
    </div>

       <!-- <footer class="footer py-4  ">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 mb-lg-0 mb-4">
              <div class="copyright text-center text-sm text-muted text-lg-start">
               
                Todos os direitos reservados <i class="fa fa-heart"></i> para
                <a href="https://www.creative-tim.com" class="font-weight-bold" target="_blank">NPK Ventures</a>.
              </div>
            </div>
          </div>
        </div>
      </footer> -->
  </main>
</template>

<script>
import CreateInvestor from "./views/CreateInvestor.vue";
import LoginAuth from "./views/LoginAuth.vue";

export default {
  name: "App",
  data() {
    return {
      isLogged:false
    };
  },
  beforeRouteEnter(to, from, next) {
    // Lógica a ser executada sempre que uma nova rota for acessada
    console.log('Nova rota acessada!');
    next();
  },
  beforeMount() {
    // this.$root.isLogged = false;
    // this.$root.tokenUser = "";
    console.log('CHAMOU AQUIIII'+ JSON.stringify(this.$root));
    this.isLoggedUser = this.$root.isLogged;

    const tokenUser = localStorage.getItem("token");

    if (!(tokenUser === null || tokenUser === undefined || tokenUser === "")) {
      this.$root.tokenUser = tokenUser;
      this.$root.isLogged = true;
      this.isLogged = true;
    }

  },
  components: {
    CreateInvestor,
    LoginAuth,
  },
  methods: {
    logout() {
      this.$root.isLogged = false;
      this.$root.tokenUser = "";
      this.isLoggedUser = false;
      localStorage.removeItem("token");
      this.$router.push({ name: "login" });
    },
    openAsideMenu(){
      let asideComponent = document.getElementById('sidenav-main');
      asideComponent.classList.remove('asideBar')
    
    },
    closeAsideMenu(){
      let asideComponent = document.getElementById('sidenav-main');
      asideComponent.classList.add('asideBar')
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.btn-burger {
  display: none;
}

#navbar > img {
  display: none;
}
.btnsInvestimentOps, .btnCloseAside, .btnSair {
  display:none!important;
}

  @media (max-width: 1200px) {
    .asideBar {
      display: none !important;
    }

    .btnCloseAside, .btnSair{
      display:block!important;
    }

    .header-responsivo  {
      background-color: #313135;
      color: #fff;
      
      & div > div > ul > li:nth-child(3) > a {
        color:#FFF!important;
      }
    }

    #navbar {
      display: flex;

      & > ul {
        display: contents;
        font-size: 10px;
      }
    }

    #navbarBlur {
      margin: 0px !important;
      border-radius: 0% !important; 
      padding: 15px 0px !important;
    }

    .btn-burger {
      display: block;
      font-size: 25px;
    }
  }

  @media (max-width: 800px) {
    #navbar {
      justify-content: space-between;
    }

    #navbarBlur {
      padding:4px 0px !important
    }
    .btnsInvestimentOps{
      display:block!important;
    }
  }

  @media (max-width: 767px) {
    #navbar > img {
      width: 100px;
      display: block;
      margin-left: auto;
    }

    #navbar > ul > li:nth-child(1) > a,
    #navbar > ul > li:nth-child(2) > a,
    #navbar > ul > li:nth-child(3) > a {
      display: none;
    }
  }
</style>
